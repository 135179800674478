import React from 'react'

import { TemplateSection, TemplatesSplash } from 'components'
import { CollectiveInfo, FoundryInfo, LaunchpadInfo, LightboxInfo } from 'helpers'

const Templates = () => (
  <main style={{ background: '#111', height: 'auto' }}>
    <TemplatesSplash />
    <TemplateSection info={FoundryInfo} />
    <TemplateSection info={LightboxInfo} reverse />
    <TemplateSection info={LaunchpadInfo} />
    <TemplateSection info={CollectiveInfo} reverse lastSection />
  </main>
)

export default Templates
